@tailwind base;
@tailwind components;
@tailwind utilities;

:root {
    @apply bg-theme text-text
}

body{
    padding: 0;
    background-image: linear-gradient(45deg, #12c2e9, #c471ed,#f64f59); 

}

* {
    --color-theme: #e6e6e6;
    --color-primary: #00001A ;
    --color-secondary: ;
    --color-text: #F5F5F5;
    --color-border: #ffbe98;
}

section{
    display: flex;
    width: 100%;
    height: 100%;
}


.b {
    border: 2px solid seagreen;
    border-radius: 8px;
}

h1{
font-size: 38px;
font-weight: bold;
}

h2{
    font-size: 24px;
    font-weight: 700;
}

.btn{
   padding: 12px 18px;
   border-radius: 4px;
   font-weight: 500;
}
.btn:hover{

    background-color: #E60000;
    transition: 0.5s ease-out;
}


